<template lang="pug">
  .VueSlickCarouselContainer.text-center
    #VueSlickCarousel.VueSlickCarousel(ref="carousel")
      slot
    .d-block.mb-3.mt-1
      button(
        v-for="(dot, index) in list"
        :key="index + 1"
        :id="index + 1"
        :class="(index + 1) === slide && 'selected_dot'"
        @click="changeSelectedDot(index)"
      ).dots.mx-1
</template>

<script>
export default {
  name: "VueSlickCarousel",

  data: () => ({
    slide: 1,
    observer: null
  }),

  mounted() {
    this.startObserver();
  },

  destroyed() {
    this.cleanObserver();
  },

  watch: {
    list(val) {
      if (val) {
        const timeout = setTimeout(() => {
          this.cleanObserver();
          this.startObserver();
          clearTimeout(timeout);
        }, 10);
      }
    }
  },

  props: ["list"],

  methods: {
    changeSelectedDot(dotIndex) {
      // this.slide = dotIndex + 1;
      this.$refs.carousel.scrollLeft = dotIndex * 237.54;
    },
    handlerObserver(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.slide = Number(entry.target?.id) || 1;
        }
      });
    },
    cleanObserver() {
      const slickItems = document.querySelectorAll(".slick-item");

      slickItems.forEach(slickItem => {
        this.observer.unobserve(slickItem);
      });

      this.unobserve = null;
    },
    startObserver() {
      const slickItems = document.querySelectorAll(".slick-item");

      this.observer = new IntersectionObserver(this.handlerObserver, {
        threshold: 1.0
      });

      slickItems.forEach(slickItem => {
        this.observer.observe(slickItem);
      });
    }
  }
};
</script>

<style scoped>
.VueSlickCarousel {
  display: flex;
  max-width: 100vw;
  overflow-x: auto;
  height: 100%;
  scroll-behavior: smooth;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  padding-left: 30px;
  padding-right: 60.45px;
}

.VueSlickCarousel::-webkit-scrollbar {
  display: none;
}

.slick-item {
  position: relative;
  max-width: 237.54px;
  min-width: 237.54px;
  width: 100%;
  margin-left: 30.45px;
  height: 307.41px;
  background-color: white;
  scroll-snap-align: center;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
  border-radius: 13px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.slick-item i {
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 4;
}

.dots {
  width: 8.53px;
  height: 8.53px;
  border-radius: 50%;
  background-color: #b8b8b8;
  display: inline-block;
  border: none;
  outline: none;
  transition: 0.2s background-color;
}

.selected_dot {
  background-color: var(--color-secondary);
}

@media screen and (max-width: 411px) {
  .VueSlickCarousel {
    width: 100vw;
    position: relative;
    left: -32px;
  }
}
</style>
