<template lang="pug">
  div.FlexBox
    Header
    template(v-if="!errorMessage")
      h2.TitleSection.pt-4.mt-3 Autorizaciones médicas Colmédica
      article(v-if="enableButton").BranchValidation.position-relative.w-100.h-100.mt-3.p-3
        p(v-for="(block) in blocks_1" :key="block").TextSection.text-start.color-colmedica-gray.text-sizing-2.mt-0 {{ block }}
        p.TextSection.text-start.color-colmedica-gray.text-sizing-2 {{ continuesText }}
        p(v-for="(block) in blocks_2" :key="block").TextSection.text-start.color-colmedica-gray.mb-2.pb-1
          | {{ block }}
        .botton-actions-footer
          .d-flex.justify-content-center
            b-button(@click="continueAutoService" variant="bluecolmedica" size="lg").btn-block.mt-5
              | Radicar solicitud
      section(v-else)
        h5.text-center.text-middle.color-colmedica-gray.mt-5 Cargando...
        .flexMiddle
          Spinner
    .text-middle.color-colmedica-gray.text-sizing-2.mt-4(v-else) Se ha presentado un error al obtener la información de la compañía y la sucursal.
    //- div(style="margin: 0px auto;")
    //-   img(class="client_logo" src="./../../assets/ComingSoonImage.png")
</template>

<script>
import Header from "./components/Header";
import Continue from "mdi-vue/ArrowRightBold.vue";
import Spinner from "../components/Spinner";

import { getBranch, getCompany } from "../../helpers/Meet";
import { mapActions, mapState } from "vuex";
import _get from "lodash/get";

export default {
  name: "BranchValidation",
  components: {
    Header,
    Continue,
    Spinner
  },
  created() {
    this.evaluateAttention();
  },
  data: () => ({
    enableButton: false,
    errorMessage: false,
    blocks_1: ["En este momento no podemos atenderte."],
    blocks_2: [
      `
        En este momento puedes radicar tu solicitud y
        enviaremos respuesta a tu correo electrónico.
      `,
      `
        Recuerda que también puedes solicitar tus
        autorizaciones médicas mediante nuestros
        canales virtuales App y Web Colmédica
        de manera rápida y segura.
      `
    ],
    continuesText: ""
  }),
  computed: {
    ...mapState({
      HorarioOutContinue: state => state.autoservice.HorarioOutContinue,
      env: state => state.env
    }),

    evaluateCompany() {
      return this.env.VUE_APP_EVALUATE_COMPANY === "true";
    },

    company() {
      return this.env.VUE_APP_COMPANY;
    },

    branch() {
      return Number(this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH);
    }
  },
  methods: {
    ...mapActions({
      setBranchInfo: "setBranchInfo",
      setHorarioOutContinue: "autoservice/setHorarioOutContinue",
      goToView: "autoservice/goToView",
      putLog: "log/put"
    }),

    continueAutoService() {
      this.setHorarioOutContinue(true);
      return this.goToView("Home");
    },

    async evaluateAttention() {
      if (!this.evaluateCompany) {
        this.putLog({
          name: "BranchValidation. Saltando verificación de compañía."
        });
        return this.goToView("Home");
      }
      try {
        const company = await getCompany(this.company);
        this.putLog({
          name: "BranchValidation. Se obtuvo la información de la compañía."
        });
        const branch = _get(
          Object.keys(company).filter(
            name => company[name].branch_id === this.branch
          ),
          "[0]"
        );
        const branchInfo = await getBranch(this.company, branch);
        this.putLog({
          name: "BranchValidation. Se obtuvo la información de la sucursal."
        });
        this.setBranchInfo(branchInfo);
        this.continuesText = branchInfo.activeBranchMessage;
        if (branchInfo.activeBranch) {
          return this.goToView("Home");
        }
        this.enableButton = true;
        this.putLog({
          name: "BranchValidation. La sucursal está cerrada."
        });
      } catch (error) {
        this.putLog({
          name:
            "BranchValidation. Error bloqueante en verificación de la compañía.",
          checked: false,
          message: error
        });
        this.errorMessage = true;
        console.error("Invalid company or branch validation", error);
      }
    }
  }
};
</script>

<style scoped>
.BranchValidation {
  line-height: 1.6rem;
}

.BranchValidation p {
  font-size: 1rem !important;
}

.buttonAction {
  width: fit-content;
  min-width: 250px;
  max-width: 400px;
  margin: auto;
}

.buttonAction span {
  margin-left: 7px;
}

.flexMiddle {
  display: flex;
  justify-content: center;
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}
</style>
