<template lang="pug">
  .ToSchedule
    .headerFixed(v-if="isntIntersection")
      button(@click="() => goToView('IndexSchedules')" type="button").button-without-styles.py-3.px-4
        svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#ffff' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
      h2.TitleSection.text-start.color-white.pt-2 Gestionar videollamada
    h2.TitleSection.text-start.pl-2.ml-4.pt-3.pb-3#headerCalendarTitle Gestionar videollamada
    .botton-actions.ml-5
      .d-flex.justify-content-between
        button(type="button" @click="() => goToView('IndexSchedules')").button-without-styles
          svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    div(v-if="!appointments.length")
      p.FontDesc.ml-2.mb-1 Aún no tienes videollamadas programadas.
      p.FontDesc.ml-2 Presiona el botón para programar una videollamada.
      b-button(
        variant="bluecolmedica"
        class="text-center"
        size="sm"
        type="button"
        @click="() => goToView('Calendar')").w-100.py-2.mx-1
        span(v-if="!loading").font-md Programar videollamada
        .py-1(v-else)
          MiniSpinner
    p.FontDesc.ml-2(v-else) Estas son las videollamadas que tienes programadas:
    ul.list_schedules
      li.Card.mb-3.p-4(v-for="(item,  k) in appointments" :key="k")
        p.font-16.mb-2
          span.TextSection Fecha:
          span.text-dark-cm.ml-1 {{day(item.startAt)}}
        p.font-16.mb-2
          span.TextSection Hora:
          span.text-dark-cm.ml-1 {{time(item.startAt)}}
        p.font-16.mb-2
          span.TextSection Trámite:
          span.text-dark-cm.ml-1 {{item.reason.servicioSelected}}
        p.font-16.mb-2
          span.TextSection Modalidad de atención:
          span.text-dark-cm.ml-1 {{item.reason.modalidad}}
        //- p.font-16.mb-2
        //-   span.TextSection Codigo de Confirmación:
        //-   span.text-dark-cm.ml-1 {{item.code}}
        p.font-16.mb-2
          span.TextSection Ingresar: 
          a(class="link-colmedica" :href="'/#/turno?code='+item.code+'&type=1'" target="_blank") aquí
        .d-flex.justify-content-end.mt-3.ContainerFlexButtons
          b-button(
            variant="bluecolmedica"
            class="text-center"
            size="sm"
            type="button"
            @click="() => handleCancel(item)").mx-1.px-3
            span.font-16 Cancelar
          b-button(
            variant="bluecolmedica"
            class="text-center"
            size="sm"
            type="button"
            @click="() => handleUpdate(item)").mx-1.px-3
            span.font-16 Reprogramar
      .botton-actions-footer(v-if="appointments.length")
        .d-flex.justify-content-center
          b-button(
            variant="bluecolmedica"
            class="text-center"
            size="sm"
            type="button"
            @click="() => goToView('Calendar')").w-100.py-2.mx-1
            span(v-if="!loading").font-md Programar otra videollamada
            .py-1(v-else)
              MiniSpinner
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import MiniSpinner from "../components/MiniSpinner";

export default {
  name: "ToSchedule",

  data: () => ({
    isntIntersection: false,
    loading: false
  }),

  components: {
    MiniSpinner
  },

  computed: {
    ...mapState({
      env: state => state.env,
      client: state => state.autoservice.client,
      appointments: state => state.appointments.appointments,
    })
  },

  async mounted() {
    this.observer = new IntersectionObserver(this.handlerObserver, {
      threshold: 1.0
    });

    this.observer.observe(document.querySelector("#headerCalendarTitle"));
    
    if(this.client){
      let queryData = '?customer.dni='+ this.client.NumeroIdentificacion + "&status=CONFIRMED";
      this.loading = true;
      await this.fetchAppointments({
          url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
          headers: {
            'token_appointments': this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY,
          },
          queryData: queryData
      });
    } else {
      this.goToView('Home')
    }
    this.loading = false;
  },

  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      fetchAppointments: "appointments/fetchAppointments",
      setAppointment: "appointments/setAppointment"
    }),

    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          this.isntIntersection = true;
        } else {
          this.isntIntersection = false;
        }
      });
    },

    day(date) {
      return moment(date).format("YYYY-MM-DD");
    },

    time(date){
      return moment(date).format("hh:mm a");
    },

    handleCancel(appointment) {
      this.setAppointment(appointment)
      this.goToView('CancelSchedule')
    },

    handleUpdate(appointment){
      this.setAppointment(appointment)
      this.goToView('CalendarRepro')
    }

  }
};
</script>

<style scoped>
.Card {
  background: #f9f9f9;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
}

.botton-actions {
  position: absolute;
  top: 1.25rem;
  /* width: 100%; */
  left: -1rem;
}

.list_schedules {
  list-style: none;
  padding: 0;
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

@media screen and (max-width: 330px) {
  .ContainerFlexButtons {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    max-width: 160px;
    margin: 0 auto;
  }

  .ContainerFlexButtons button {
    margin-bottom: 0.5rem;
  }
}
</style>
