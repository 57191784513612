<template lang="pug">
  .IndexSchedules
    Header
    .withoutShowFinishView(v-if="!loading")
      h2.TitleSection.mt-4.pt-3(style="color: #2BB8E6") Oficina Virtual
      //- TODO: p Azxul claro
      p.TextSection.mt-3.mx-2(style="color: #025190") Ahora puedes ahorrar tiempo agendando tu videollamada con un asesor
      button(
        @click="() => goToView('ToSchedule')"
      ).w-100.button-without-styles.my-2.mt-5
        stack-item.mx-auto.align-items-center.d-block
          .pr-5
            | Programa una videollamada con uno de nuestros asesores
      button(
        @click="() => goToView('MeetingMeToGetTurn')"
      ).w-100.button-without-styles.my-2.mt-3
        stack-item.btn-block.align-self-center.mx-auto
          .pr-5
            | ¿Deseas esperar? ¡Hacemos la fila por ti!
      //- button(
      //-   @click="getTurnWaiting()"
      //- ).w-100.button-without-styles.my-2.mt-3
      //-   stack-item.btn-block.align-self-center.mx-auto Tomar turno y esperar
    h5(v-else).text-center.text-middle.color-colmedica-gray.mt-5 Cargando...
        .flexMiddle
          Spinner
    .fakeElementToScroll
</template>

<script>
import { mapActions, mapState } from "vuex";
import _get from "lodash/get";

import asterisker from "@/helpers/asteriskHidden";
import Header from "./components/Header";
import StackItem from "../components/StackItem.vue";
import { getOneUseToken } from "../../helpers/Meet";
import { utf8_to_b64 } from "../../utils/decoder";
import Spinner from "../components/Spinner";
import { getBranch, getCompany } from "../../helpers/Meet";

const axios = require("axios");

export default {
  name: "IndexSchedules",

  data: () => ({
    assingTurn: "A569",
    loading: true,
    showSchedule: 0
  }),

  async created(){

    this.loading = true;

    // Check Schedules
    const company = await getCompany(this.env.VUE_APP_COMPANY);
    const branch = _get(
      Object.keys(company).filter(
        name => company[name].branch_id == this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH
      ),
      "[0]"
    );
    const branchInfo = await getBranch(this.env.VUE_APP_COMPANY, branch);
    if (!branchInfo.activeBranch) {
      return this.goToView("OutOperationsFinish");
    }

    // Request to awTime
    axios.get(  `${this.env.VUE_APP_DEBMEDIA_SERVER.replace("/api/v1", '')}/api/queues/branchInfo/queue/${this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH}` ).then(res => {
      let turnInfo = res.data;
      turnInfo = turnInfo ? turnInfo.monitorInfo.waitingRooms : [];
      turnInfo = turnInfo[0].avgWaitingTime == '--' ? 0 : parseInt(turnInfo[0].avgWaitingTime);
      if(turnInfo < this.env.VUE_APP_MAX_WAITING_TIME){
        this.getTurnWaiting();
      } else {
        this.loading = false;
      }
    });

  },

  // mounted() {
  //   this.observer = new IntersectionObserver(this.handlerObserver, {
  //     threshold: 1.0
  //   });

  //   this.observer.observe(document.querySelector("#headerCalendarTitle"));
  // },

  components: {
    StackItem,
    Header,
    Spinner
  },

  computed: {
    ...mapState({
      client: state => state.autoservice.client,
      b64final: state => state.autoservice.b64final,
      service: state => state.autoservice.service,
      env: state => state.env,
      branchInfo: state => state.branchInfo
    }),

    phone() {
      let pure = _get(this.client, "Celular") || "";
      return asterisker(String(pure), 0, 4);
    },

    email() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}${
        splited[1] ? "@" + asterisker(splited[1], 0, splited[1].length - 2) : ""
      }`;
    }
  },

  methods: {
    ...mapActions({
      goToView: "autoservice/goToView"
    }),

    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          this.isntIntersection = true;
        } else {
          this.isntIntersection = false;
        }
      });
    },

    async getTurnWaiting() {
      let to = await getOneUseToken();
      let jsonB64 = JSON.stringify({
        ...this.b64final,
        sv: this.service,
        to
      });
      this.loading = false;
      this.$router.push({
        name: "Decoder",
        query: { pr: utf8_to_b64(jsonB64) }
      });
    }
  }
};
</script>

<style>
.IndexSchedules {
  height: calc(100% - 150px);
}

.AssignmentText {
  font-size: 21px;
  line-height: 28px;
}

.ImportantAsignment {
  color: var(--color-secondary);
}

.mx-2.TextSection.desc {
  margin-top: 38.46px;
}

.mx-2.Link {
  cursor: pointer;
  color: var(--color-secondary);
}

.mx-2.Link:hover {
  text-decoration: none;
  color: var(--color-secondary);
}

.boxTurnAssigned {
  background: #f9f9f9;
  border-radius: 21.4881px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
}

.showFinishView {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 380px;
}

.buttons-actions {
  position: fixed;
  bottom: 0;
  margin-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

.stylesButton {
  align-items: flex-start !important;
}

.StackItem span {
  text-align: start;
}

.buttonBack {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20.3521px;
  line-height: 27px;

  text-align: center;
  color: #081e3d;
}
</style>
