<template lang="pug">
.Alerted
  Header
  .withoutShowFinishView
    h2.TitleSection.mt-4.pt-3
      | {{ selectTitle }}
    p.TextSection.mt-3.mx-2 No hay problema, puedes elegir entre las siguientes opciones:
    button(
      @click="() => goToView('IndexSchedules')"
    ).w-100.button-without-styles.my-2.mt-5
      stack-item.btn-block.align-self-center.mx-auto Tomar un nuevo turno
    //- button(
    //-   @click="() => goToView('IndexSchedules')"
    //- ).w-100.button-without-styles.my-2.mt-3
    //-   stack-item.btn-block.align-self-center.mx-auto Agendar una cita
    .fakeElementToScroll
  .buttons-actions
    b-button(variant="whitecolmedica").buttonBack.mx-auto.d-block.mb-2 Ya puedes cerrar esta ventana
    a(
      @click="() => goToView('ToSchedule')"
    ).mx-2.Link.mt-2.pt-2.text-center.d-block Ver videollamadas programadas
</template>

<script>
import Header from "./components/Header";
import StackItem from "../components/StackItem.vue";

import asterisker from "@/helpers/asteriskHidden";
import _get from "lodash/get";
import { utf8_to_b64 } from "@/utils/decoder";
import { getOneUseToken } from "@/helpers/Meet";
import { mapActions, mapState } from "vuex";

export default {
  name: "Alerted",

  data: () => ({
    assingTurn: "A569",
    modeType: 0
  }),

  components: {
    StackItem,
    Header
  },

  computed: {
    ...mapState({
      client: state => state.autoservice.client,
      b64final: state => state.autoservice.b64final,
      service: state => state.autoservice.service
    }),

    phone() {
      let pure = _get(this.client, "Celular") || "";
      return asterisker(String(pure), 0, 4);
    },

    email() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}${
        splited[1] ? "@" + asterisker(splited[1], 0, splited[1].length - 2) : ""
      }`;
    },

    selectTitle() {
      if (this.modeType === 0) {
        return "Lamentablemente tu turno A309 ya finalizó";
      } else if (this.modeType === 1) {
        return "Tu cita aún no ha iniciado...";
      } else {
        return "Lamentablemente tu cita ha vencido";
      }
    }
  },

  methods: {
    ...mapActions({
      goToView: "autoservice/goToView"
    }),
    async getTurnWaiting() {
      let to = await getOneUseToken();
      let jsonB64 = JSON.stringify({
        ...this.b64final,
        sv: this.service,
        to
      });
      this.$router.push({
        name: "Decoder",
        query: { pr: utf8_to_b64(jsonB64) }
      });
    }
  }
};
</script>

<style>
.Alerted {
  height: calc(100% - 150px);
}

.AssignmentText {
  font-size: 21px;
  line-height: 28px;
}

.ImportantAsignment {
  color: var(--color-secondary);
}

.mx-2.TextSection.desc {
  margin-top: 38.46px;
}

.mx-2.Link {
  cursor: pointer;
  color: var(--color-gray);
  text-decoration: underline;
}

.mx-2.Link:hover {
  color: var(--color-gray);
  text-decoration: underline;
}

.boxTurnAssigned {
  background: #f9f9f9;
  border-radius: 21.4881px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
}

.showFinishView {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 380px;
}

.buttons-actions {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
  background-color: var(--color-white);
  margin-bottom: -2px;
  max-width: 331.7px !important;
}

.buttonBack {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20.3521px;
  line-height: 27px;

  text-align: center;
  color: #081e3d;
  opacity: 1;
}
</style>
