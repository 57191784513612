<template lang="pug">
  .ConfirmSchedule
    h2(v-if="!modeScheduled").TitleSection.text-start.pl-2.ml-4.pt-3.pb-3#headerCalendarTitle {{appointment.code ? 'Confirmar tu videollamada reprogramada' : 'Confirmar tu videollamada'}} 
    .headerFixed(v-if="isntIntersection && !modeScheduled")
      button(@click="() => goToView('Calendar')" type="button").button-without-styles.py-3.px-4
        svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#ffff' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
      h2.TitleSection.text-start.color-white.pt-2 {{appointment.code ? 'Confirmar tu videollamada reprogramada' : 'Confirmar tu videollamada'}} 
    .botton-actions.ml-5(v-if="!modeScheduled")
      .d-flex.justify-content-between
        button(type="button" @click="() => goToView('Calendar')").button-without-styles
          svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    h2(v-else-if="modeScheduled &&  responseAppointment.status != 'CONFIRMED'").TitleSection.pt-5.mb-4.pb-1.warning Error agendando la cita 
      br
      br
      p.font-16.mb-0.text-error {{responseAppointment.message}}
    h2(v-else).TitleSection.pt-5.mb-4.pb-1.ProgramTitle
      span.red ¡
      span.blue Videollamada
      span.ml-1.dark-blue programada
      span.red !
    .form-data.mb-3.mt-3.py-4.mx-1.px-4
      p.font-16.mb-0 Nombre:
        strong.ml-1 {{ client.Nombres }}
      p.font-16.mb-0 Fecha y hora:
        strong.ml-1 {{ confirmDay }}
      p.font-16.mb-0(v-if="servicioSelected") Trámite:
        strong.ml-1 {{ servicioSelected.label }}
      //- p.font-16.mb-0 Modalidad de atención:
      //-   strong.ml-1 {{ modeAtt }}
      //- p(v-if="responseAppointment.code").font-16.mt-4.text-center Número de confirmación de cita:
      //-   strong.ml-1.d-block {{responseAppointment.code}}
      a.mx-2.LinkColored.mt-4.pt-2.pb-1.text-center.d-block Te enviaremos la confirmación a tu correo electrónico
        p.mb-0.ml-1.d-flex.flex-wrap.justify-content-center
          span {{ email1 }}
          span {{ email2 }}
        span.ml-1 y a tu número celular
        span.ml-1 {{ phone }}
    p(v-if="!modeScheduled").grayTextDesc.mt-5.pt-4 Recuerda que también puedes solicitar tus autorizaciones médicas mediante nuestros canales virtuales App y Web Colmédica de manera rápida y segura.
    .botton-actions-footer
      .d-flex.justify-content-center
        b-button(
          variant="bluecolmedica"
          class="text-center"
          size="sm"
          type="button"
          @click="confirmAppointment"
          :loading="loading"
          v-if="!modeScheduled").w-100.py-2.mx-1
          span(v-if="!loading").font-md {{appointment.code ? 'Confirmar tu videollamada reprogramada' : 'Confirmar tu videollamada'}} 
          .py-1(v-else)
            MiniSpinner
        //- b-button(
        //-   variant="whitecolmedica"
        //-   class="text-center"
        //-   disabled
        //-   size="sm"
        //-   @click="() => goToView('Home')"
        //-   type="button"
        //-   v-else).w-100.py-2.mx-1.buttonOpacity
        //-   span.font-md Ya puedes cerrar esta ventana
      b-button(
        variant="bluecolmedica"
        @click="() => goToView('ToSchedule')"
        v-if="modeScheduled"
      ).mt-2.d-block.text-center.w-100.py-2.mx-1.mb-4: span(style="color: white;").TextSection Ver videollamadas programadas
</template>

<script>
import asterisker from "@/helpers/asteriskHidden";
import _get from "lodash/get";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import MiniSpinner from "../components/MiniSpinner";
import clientInfo from "@/helpers/clientInfo";
import { getCompany } from "@/helpers/Meet";
import LS from "@/helpers/localStorage";

export default {
  name: "ConfirmSchedule",

  data: () => ({
    name: "Susan Fajardo",
    date: "13/02/2022, 9:00a.m.",
    serviceData: "Cardiologia",
    modeAtt: "Videollamada",
    modeScheduled: false,
    isntIntersection: false,
    loading: false
  }),

  components: {
    MiniSpinner
  },

  mounted() {
    this.observer = new IntersectionObserver(this.handlerObserver, {
      threshold: 1.0
    });

    this.observer.observe(document.querySelector("#headerCalendarTitle"));
  },

  computed: {
    ...mapState({
      client: state => state.autoservice.client,
      b64final: state => state.autoservice.b64final,
      service: state => state.autoservice.service,
      selectedDay: state => state.appointments.selectedDay,
      env: state => state.env,
      servicioSelected: state => state.autoservice.servicioSelected,
      responseAppointment: state => state.appointments.responseAppointment,
      appointment: state => state.appointments.appointment
    }),

    phone() {
      let pure = _get(this.client, "Celular") || "";
      return asterisker(String(pure), 0, 4);
    },

    email1() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}`;
    },

    email2() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${
        splited[1] ? "@" + asterisker(splited[1], 0, splited[1].length - 2) : ""
      }`;
    },

    confirmDay() {
      return moment(this.selectedDay.zonedStartDate).format(
        "DD/MM/YYYY hh:mm a"
      );
    }
  },

  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      createAppointment: "appointments/createAppointment",
      updateAppointment: "appointments/updateAppointment",
      sendNotification: "appointments/sendNotification",
    }),

    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          this.isntIntersection = true;
        } else {
          this.isntIntersection = false;
        }
      });
    },

    async confirmAppointment() {

      this.loading = true;

      if (this.appointment.code) {
        let data = {
          branch: this.appointment.branch?.id,
          status: "CONFIRMED",
          schedule: this.appointment.schedule?.id,
          code: this.appointment.code,
          startAt: this.selectedDay.zonedStartDate.replace("-05:00", "-0500")
        };

        await this.updateAppointment({
          url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
          headers: {
            token_appointments: this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY
          },
          queryData: "",
          data: data
        });
      } else {

        console.log("client")
        console.log(this.client)
        let service = {};
        // search tramite
        if(this.client.tr){

          const company = await getCompany(this.env.VUE_APP_COMPANY);
          console.log(company)
          const branch = _get(
            Object.keys(company).filter(
              name => company[name].branch_id == this.client.su
            ),
            "[0]"
          );
          console.log(branch)
          const turns = company[branch]?.turnos;
          console.log(turns)
          
          if(turns)
            service = turns.find(t=> t.tramite_id == this.client.tr)?.tramite_name;

        }

        // Create appointment
        let data = {
          branch: this.env.VUE_APPOINTMENTS_DEFAULT_BRANCH,
          schedule: this.env.VUE_APPOINTMENTS_DEFAULT_SCHEDULE,
          startAt: this.selectedDay.zonedStartDate.replace("-05:00", "-0500"), //remove : character
          user: {
            firstName: this.client.Nombres,
            lastName: this.client?.PrimerApellido + " " + (this.client?.SegundoApellido || ''),
            dni: this.client.NumeroIdentificacion,
            cuit: 1,
            email: this.client.Correo,
            phone: this.client.Celular
          },
          reason: JSON.stringify({
            servicioSelected: this.servicioSelected?.label || service || "No registra",
            modalidad: this.modeAtt,
            tramite:
              this.client.tr || this.env.VUE_APP_DEBMEDIA_DEFAULT_TRAMITE,
            marcacion: this.client.ma || clientInfo.ma || LS.getItem("marcacion"),
            so: this.client.so,
            fu: this.client.fu || clientInfo.fu || LS.getItem("fuente"),
            br: this.client.br || clientInfo.br,
            os: this.client.os || clientInfo.os
          })
        };
        
        this.loading = true;

        await this.createAppointment({
          url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
          headers: {
            token_appointments: this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY
          },
          queryData: "",
          data: data
        });
      }

      // Notification by sms
      this.sendNotification({
        code: this.responseAppointment.code,
        Celular: this.client.Celular,
        selectedDay: moment(this.selectedDay.zonedStartDate, "YYYY-MM-DD, HH:mm:ss")
      });

      this.loading = false;
      this.modeScheduled = true;
    }
  }
};
</script>

<style scoped>
.botton-actions {
  position: absolute;
  top: 1.25rem;
  /* width: 100%; */
  left: -1rem;
}

.form-data {
  margin-top: 40px;
  background-color: #f9f9f9;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 21.4881px;
}

.LinkColored {
  cursor: pointer;
  color: var(--color-secondary);
  line-height: 20px;
  margin-top: 28.44px;
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

.LinkUnderlined {
  color: var(--color-gray);
  text-decoration: underline;
}

.grayTextDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  color: #66707e;
}

.blue {
  color: rgb(5, 169, 221);
}
.red {
  color: #ff0304;
}
.ProgramTitle {
  display: flex;
  overflow-wrap: normal;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.buttonOpacity {
  opacity: 1;
}

.text-error {
  color: #ff0304;
}
</style>
