<template lang="pug">
  .Calendar
    header(v-if="isntIntersection").headerCalendar
      h2.TitleSection.text-start.pl-2.ml-4.pt-3.pb-3#headerCalendarTitle {{ title }}
      .botton-actions.ml-5
        .d-flex.justify-content-between
          button(type="button" @click="() => goToView('ToSchedule')").button-without-styles
            svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
              path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    h2.TitleSection.text-start.pl-2.ml-4.pt-3.pb-3#headerCalendarTitle {{ title }}
    .botton-actions.ml-5
      .d-flex.justify-content-between
        button(type="button" @click="() => goToView('ToSchedule')").button-without-styles
          svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    p.FontDesc.mb-2 Puedes eligir la fecha y hora adecuada filtrando en el calendario.
    span.sizeBanner(v-if="servicioSelected") Trámite:
      strong.ml-1 {{servicioSelected.label}}
    //- v-date-picker(
    //-   is-expanded
    //-   v-model="date"
    //-   locale="es"
    //-   mode="date"
    //-   @dayclick="handleClickCalendar"
    //-   @update:from-page="sendRange").CalendarComponent.px-4.pt-2.mt-3
    .max-width-100
      v-calendar(
        is-expanded
        :available-dates="availablesDays"
        :disabled-dates="disableDays"
        ref="calendar"
        locale="es"
        @dayclick="handleClickCalendar"
        @update:from-page="sendRange").CalendarComponent.px-4.pt-2.mt-3
    p(
      v-if="transformToMoment(date) !== 'Invalid date'"
    ).TextSection.subTitle.text-start.mb-2.mt-3 Hora disponible para: {{ transformToMoment(date) }}
    p(v-else).TextSection.subTitle.text-start.mb-2.mt-3 Hora disponible
    ul.BannerContainer
      //- button(
      //-   v-for="item in ['1', '2', '3', '4', '5', '6']"
      //-   :key="item"
      //-   @click="(event) => selectHour(event, item)").button-without-styles.mt-3.mb-1.mr-2
      //-   li.Banner.position-relative
      //-     .buttonSelectHour
      //-     span.p-1.px-2 9:20am
      button(
        v-for="(dateByDay, dateByDayIndex) in datesByDay"
        :key="dateByDayIndex"
        @click="(event) => handleGoToDate(event, dateByDay)").button-without-styles.mt-3.mb-1.mr-2
        li.Banner.position-relative
          .buttonSelectHour
          span.p-1.px-2 {{ dateByDay.hora }}
    .botton-actions-footer
      .d-flex.justify-content-center
        b-button(
          variant="bluecolmedica"
          size="sm"
          type="button"
          :disabled="!date || !hour"
          @click="goToSchedule").w-100.py-2.mx-1.text-center
          span.font-md {{ title }} videollamada
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";

export default {
  name: "Calendar",

  data: () => ({
    date: new Date(),
    datesByDay: [],
    label: "",
    availablesDays: {},
    disableDays: {},
    hour: "",
    isntIntersection: false
  }),

  computed: {
    ...mapState({
      env: state => state.env,
      availablesDaysMonth: state => state.appointments.availablesDaysMonth,
      servicioSelected: state => state.autoservice.servicioSelected
    })
  },

  props: ["title"],

  async mounted() {
    try {
      const options = {};
      this.observer = new IntersectionObserver(this.handlerObserver, options);
      this.observer.observe(document.querySelector("#headerCalendarTitle"));
    } catch (error) {
      console.log(error);
    }
  },

  beforeDestroy() {
    this.cancelObserver();
  },

  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      fetchAvailablebyMonths: "appointments/fetchAvailablebyMonths",
      setDay: "appointments/setDay",
      setAppointment: "appointments/setAppointment"
    }),

    async fetchDaysFromDate(date) {
      // Check available days
      let day = date.format("YYYY-MM-DDTh:mm:ss");

      console.log(`${day}${this.env.VUE_APP_ZONE.replace(":", "")}`);
      await this.fetchAvailablebyMonths({
        url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
        headers: {
          token_appointments: this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY
        },
        scheduleId: this.env.VUE_APPOINTMENTS_DEFAULT_SCHEDULE,
        branchId: this.env.VUE_APPOINTMENTS_DEFAULT_BRANCH,
        startAt: `${day}${this.env.VUE_APP_ZONE.replace(":", "")}`
      });

      if (this.availablesDaysMonth && this.availablesDaysMonth.slots) {
        let availables = {};
        this.availablesDaysMonth.slots.map(
          a =>
            (availables[moment(a.zonedStartDate).format("YYYY:MM:DD")] = {
              start: new Date(
                moment(a.zonedStartDate).format("YYYY"),
                moment(a.zonedStartDate).format("MM") - 1,
                moment(a.zonedStartDate).format("DD")
              ),
              end: new Date(
                moment(a.zonedStartDate).format("YYYY"),
                moment(a.zonedStartDate).format("MM") - 1,
                moment(a.zonedStartDate).format("DD")
              )
            })
        );
        let availablesArray = [];
        for (let index in availables) {
          availablesArray.push(availables[index]);
        }

        this.availablesDays = availablesArray.map(a => a);
        this.disableDays = {};
      } else {
        this.availablesDays = [];
        (this.disableDays = {
          start: null,
          end: new Date(date.format("YYYY"), date.format("MM") - 1, 31)
        }),
          {
            start: new Date(date.format("YYYY"), date.format("MM") - 1, 1),
            end: null
          };
      }
    },

    handleClickCalendar(day) {
      if (day.isDisabled) return;

      const $vcFocusables = document.querySelectorAll(".vc-focusable");

      if (day.isFocusable) {
        $vcFocusables.forEach($vcFocusable => {
          $vcFocusable.classList.remove("dogEl");
        });
        day.el.classList.add("dogEl");
      }
      this.date = day.date;
      const dayText = moment(day.date).format("dddd");
      this.label = `${dayText} ${moment(day.date).format("DD")} de ${moment(
        day.date
      ).format("MMMM")}`;
      this.getDatesByDay(day);
    },

    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          this.isntIntersection = true;
        } else {
          this.isntIntersection = false;
        }
      });
    },

    sendRange(event) {
      this.datesByDay = [];
      this.label = "";
      let month = event.month > 9 ? event.month : "0" + event.month;
      this.fetchDaysFromDate(moment(`${event.year}-${month}-01`));
    },

    selectHour(event, item) {
      const [...$buttonHours] = document.querySelectorAll(".buttonSelectHour");

      $buttonHours.map($buttonHour => {
        $buttonHour.parentElement.classList.remove("selectedHour");
      });

      event.target.parentElement.classList.toggle("selectedHour");
      this.hour = item;
    },

    cancelObserver() {
      const $headerCalendarTitle = document.querySelector(
        "#headerCalendarTitle"
      );

      if ($headerCalendarTitle) {
        this.observer.unobserve($headerCalendarTitle);
      }
    },

    transformToMoment(date) {
      return moment(date).format("D/M/YYYY");
    },

    goToSchedule() {
      this.cancelObserver();
      // reset previus data
      if (this.title == "Programar") this.setAppointment({});
      this.goToView("ConfirmSchedule");
    },

    async getDatesByDay(day) {
      this.datesByDay = [];

      if (this.availablesDaysMonth.slots) {
        let slots = this.availablesDaysMonth.slots.filter(s => {
          return (
            moment(s.startDate).format("YYYY-MM-DD") >=
              moment(day.date).format("YYYY-MM-DD") &&
            moment(s.startDate).format("YYYY-MM-DD") <=
              moment(day.date).format("YYYY-MM-DD")
          );
        });

        let slotsAvailavility = slots.filter(slot => slot.availability > 0);

        this.datesByDay = slotsAvailavility.map(a => {
          let hour = a.zonedStartDate
            .replace("T", " ")
            .replace(`:00${this.env.VUE_APP_ZONE}`, "");
          return {
            ...a,
            hora: moment(hour).format("h:mm A")
          };
        });

        if (moment(day.date).format("DD") == moment().format("DD")) {
          this.datesByDay = this.datesByDay.filter(s => {
            if (
              moment(s.zonedStartDate).format("YYYY-MM-DD HH:mm") >
              moment().format("YYYY-MM-DD HH:mm")
            ) {
              return s;
            }
          });
        }
      }
    },

    handleGoToDate(event, dateByDay) {
      const [...$buttonHours] = document.querySelectorAll(".buttonSelectHour");

      $buttonHours.map($buttonHour => {
        $buttonHour.parentElement.classList.remove("selectedHour");
      });

      event.target.parentElement.classList.toggle("selectedHour");
      // this.hour = item;

      this.hour = dateByDay.hora;

      this.setDay(dateByDay);
    }
  }
};
</script>

<style scoped>
.botton-actions {
  position: absolute;
  top: 1.25rem;
  /* width: 100%; */
  left: -1rem;
}

.CalendarComponent {
  background: none;
  border: 0.616634px solid #b8b8b8;
  box-sizing: border-box;
  box-shadow: 0px 2.46654px 19.7323px rgba(170, 170, 170, 0.03);
  border-radius: 7.39961px;
}

.sizeBanner {
  font-size: 14px;
  line-height: 19px;
}

.subTitle {
  font-size: 18px;
  line-height: 24px;
}

.BannerContainer {
  display: flex;
  flex-wrap: wrap;
}

.Banner {
  background: #e4e4e4;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: fit-content;
  /* margin-right: 15px; */
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

.selectedHour {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.buttonSelectHour {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}

.headerCalendar {
  position: fixed;
  max-width: 459px;
  background-color: var(--color-primary);
  z-index: 10;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.headerCalendar .TitleSection {
  color: var(--color-white);
  margin-top: 18px;
  margin-bottom: 10px;
  padding: 0 !important;
  padding-left: 40px !important;
}

.headerCalendar svg path {
  stroke: var(--color-white);
}

.max-width-100 {
  max-width: 100%;
  overflow-x: auto;
}

/* .vc-weekday::before:nth-child(3) {
  content: "Mi";
} */
</style>
